<template>
  <div class="warningDialog">
    <el-dialog title="发送警告消息" :visible.sync="dialogVisible" width="40%">
      <div class="choseReason">
        <div class="title">警告原因:</div>
        <div class="optionReason">
          <el-checkbox-group v-model="reasons">
            <el-row v-for="{ value, label } in reasonOption" :key="value" style="margin-bottom: 20px">
              <el-checkbox :label="value">{{ label }}</el-checkbox>
            </el-row>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer">
        <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="sendWarning" :loading="warningLoading">发送警告</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { reasonOption } from './option'
import { sendWarning } from '@/api/user'
export default {
  components: {},
  props: {
    WarningDialogVisible: {
      type: Boolean,
      default: false
    },
    init: {
      type: Function
    },
    getUserData: {
      type: Function
    }
  },
  data() {
    return {
      dialogVisible: false,
      warningLoading: false,
      reasonOption,
      reasons: []
    }
  },
  computed: {},
  watch: {
    WarningDialogVisible: {
      handler(newVal) {
        this.dialogVisible = newVal
      }
    },
    dialogVisible: {
      handler(newVal) {
        this.$emit('update:WarningDialogVisible', newVal)
        if (!newVal) {
          this.reasons = []
        }
      }
    }
  },
  methods: {
    async sendWarning() {
      if (!this.reasons.length) {
        this.$message.warning('请选择警告原因')
        return
      }
      const query = {
        userId: this.$route.query.id,
        content: this.reasons
      }
      this.warningLoading = true
      const { code } = await sendWarning(query)
      if ($SUC({ code })) {
        this.$message.success('操作成功')
        this.getUserData()
        this.init()
      } else {
        this.$message.error('操作失败')
      }
      this.warningLoading = false
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
.warningDialog {
  .choseReason {
    display: flex;
    .title {
      width: 80px;
      font-weight: 600;
    }
    .optionReason {
      ::v-deep .el-checkbox__label {
        font-weight: 400;
      }
    }
  }
}
</style>
