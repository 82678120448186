export const reasonOption = [
  {
    label: '上传违规图片',
    value: 'uploadViolation'
  },
  {
    label: '订单恶意维权',
    value: 'badMaintain'
  },
  {
    label: '恶意取消订单',
    value: 'badCancel'
  }
]